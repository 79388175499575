import '../sass/highlights-text.scss';

(() => {
    const $highlightsText = document.querySelectorAll('.highlights-text');

    if (!$highlightsText) return;

    $highlightsText.forEach($highlightsText => {
        const $text = $highlightsText.querySelector('.highlights-text__text');
        const $textInner = $highlightsText.querySelector('.highlights-text__text-inner');

        const initHighlightsTextTopPosition = () => {
            if (!$text || !$textInner) return;

            let scrollFraction = ($text.getBoundingClientRect().top + $textInner.clientHeight * 4) / (window.innerHeight + $textInner.clientHeight * 3);

            scrollFraction = 1 - scrollFraction;

            if (scrollFraction > 1) scrollFraction = 1;
            if (scrollFraction < 0) scrollFraction = 0;

            $textInner.style.top = `${window.innerHeight - $textInner.clientHeight - (window.innerHeight * scrollFraction)}px`;
        };

        window.addEventListener('resize', initHighlightsTextTopPosition);
        window.addEventListener('scroll', initHighlightsTextTopPosition);

        initHighlightsTextTopPosition();
    });
})();
